<sass-helper></sass-helper>

<div class="card card-tasks">
	<div
		(resized)="onResized($event)"
		style="display: inline-block"
		class="card-body"
		id="card-body-id"
	>
		<lib-loading-spinner
			*ngIf="isLoading"
			[loadingMessage]="'Loading Playback'"
			[vehicle]="'truck'"
		></lib-loading-spinner>
		<div
			style="height: 100%; width: 100%; display: flex"
			[hidden]="isLoading"
		>
			<!-- <div style="width: 50%; height: 100%; display: inline-block;" > </div> -->

			<div
				[style.width.%]="split1Percent"
				style="height: 100%; display: inline-block"
			>
				<div
					style="
						height: 100%;
						width: 100%;
						display: flex;
						flex-flow: column;
					"
				>
					<div *ngIf="realData.length === 0 && isLoading === false">
						No data for currently selected configuration.
					</div>
					<div
						style="height: 80%; width: 100%; flex: 1"
						*ngIf="realData.length > 0"
					>
						<div
							*ngIf="realData.length > 0"
							style="width: 100%"
							id="{{ mapDiv }}"
							[style.height.%]="
								showTable && showMap
									? 50
									: !showTable && showMap
									? 100
									: 0
							"
							[hidden]="showMap === false"
						></div>
						<div
							id="noPointsMessageContainer"
							*ngIf="realData.length === 0 && isLoading === false"
						>
							<lib-centered-message
								centeredMessage="No data for currently selected configuration."
							>
							</lib-centered-message>
						</div>

						<div
							style="
								width: 100%;
								overflow-y: auto;
								overflow-x: hidden;
							"
							[style.height.%]="
								showTable && showMap
									? 50
									: showTable && !showMap
									? 100
									: 0
							"
						>
							<table class="table" [hidden]="showTable === false">
								<thead class="text-primary">
									<tr>
										<th>Name</th>
										<th>Value</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let column of gridSettings.columnsConfig
										"
									>
										<td>{{ column.title }}</td>
										<td *ngIf="column.title !== 'Date'">
											{{
												realData[
													this.sliderValuePlayback - 1
												][column.field]
											}}
										</td>
										<td *ngIf="column.title === 'Date'">
											{{
												formatLabelDate(
													realData[
														this
															.sliderValuePlayback -
															1
													][column.field]
												)
											}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div *ngIf="realData.length > 0 && isLoading === false">
						<lib-playback-controller
							[liveStream]="listeningToSignalRMessages"
							[showMap]="showMap"
							(newToggle)="newToggle($event)"
							(newIndex)="newIndex($event)"
							[data]="realData"
							[sliderValue]="sliderValuePlayback"
							[currentDataLength]="realData.length"
							style="width: 100%"
						></lib-playback-controller>
						<div></div>
					</div>
				</div>
			</div>

			<div
				[style.width.%]="split2Percent"
				style="height: 100%; display: inline-block"
			>
				<mat-tab-group
					style="width: 100% !important"
					[selectedIndex]="materialTabSelected"
					(selectedTabChange)="matTabHasChanged($event, 'parent')"
				>
					<mat-tab label="Configuration">
						<div
							[style.height.%]="100"
							[style.width.%]="100"
							style="margin-top: 10px"
						>
							<div [style.height.%]="100">
								<mat-tab-group>
									<mat-tab label="Quick Setup">
										<div
											style="
												height: 100%;
												overflow-y: auto;
											"
										>
											<form
												[formGroup]="form"
												(submit)="submitForm(form)"
											>
												<div
													class="container"
													style="
														margin-top: 10px;
														margin: 0px;
													"
												>
													<div
														class="row"
														style="width: 100%"
													>
														<div class="row">
															<div
																class="form-group"
															>
																<h4
																	style="
																		text-align: left;
																		font-weight: bold;
																		margin-top: 10px;
																	"
																>
																	1. Time
																	Selection
																</h4>
																<select
																	style="
																		margin-left: 20px;
																	"
																	class="form-control"
																	title="State"
																	id="timeBack"
																	formControlName="timeBack"
																	(change)="
																		applyDateSelection()
																	"
																>
																	<option
																		class="form-control"
																		value="10"
																	>
																		Last 10
																		minutes
																	</option>
																	<option
																		class="form-control"
																		value="60"
																	>
																		Last
																		Hour
																	</option>
																	<option
																		class="form-control"
																		value="120"
																	>
																		Last Two
																		Hours
																	</option>
																	<option
																		class="form-control"
																		value="180"
																	>
																		Last
																		Three
																		Hours
																	</option>
																	<option
																		class="form-control"
																		value="240"
																	>
																		Last
																		Four
																		Hours
																	</option>
																</select>
															</div>
														</div>

														<div
															*ngIf="
																!isTimeSelectionValid
															"
														>
															<p>
																Cannot retrieve
																playback. Please
																select a
																timeline that is
																no greater than
																4 hours.
															</p>
														</div>
														<h6
															style="
																text-align: left;
															"
														>
															<button
																class="btn btn-dark-gray"
																style="
																	display: inline;
																	margin-left: 20px;
																"
																(click)="
																	editTags()
																"
																type="button"
															>
																Edit Tags
															</button>
														</h6>
													</div>

													<div class="row">
														<div>
															<h4
																*ngIf="
																	jbtStandardObservationList.length >
																	0
																"
																style="
																	margin-top: 15px;
																	font-weight: bold;
																	text-align: left;
																"
															>
																2. Configuration
																Summary:
															</h4>
															<h6
																*ngIf="
																	jbtStandardObservationList.length >
																	0
																"
																style="
																	margin-top: 15px;
																	text-align: left;
																	text-decoration: underline;
																	margin-left: 20px;
																"
															>
																Tags:
															</h6>
															<p
																style="
																	margin-left: 20px;
																"
																*ngFor="
																	let o of selectedTags
																"
															>
																{{
																	dataService
																		.cache
																		.jbtStandardObservationsObject[
																		o
																	]?.Name
																}}
															</p>
															<h6
																style="
																	margin-top: 15px;
																	margin-left: 20px;
																	text-decoration: underline;
																"
															>
																Live Data:
															</h6>

															<p
																*ngIf="
																	listeningToSignalRMessages &&
																	isTimeSelectionValid
																"
																style="
																	margin-left: 20px;
																"
															>
																Data will update
																as new events
																come in.
															</p>

															<p
																*ngIf="
																	!listeningToSignalRMessages &&
																	isTimeSelectionValid
																"
																style="
																	margin-left: 20px;
																"
															>
																Data will NOT
																update as new
																events come in.
															</p>
															<button
																class="btn btn-dark-gray"
																style="
																	display: inline;
																	margin-left: 20px;
																"
																(click)="
																	getDataForDateRange(
																		{
																			form: form,
																			getObservationsForDefaultList: true
																		}
																	)
																"
																type="button"
																[disabled]="
																	!isTimeSelectionValid
																"
															>
																Play
															</button>
															<mat-checkbox
																(change)="
																	toggleMap(
																		$event
																	)
																"
																[checked]="
																	showMap
																"
																style="
																	margin-left: 10px;
																"
																>Map
															</mat-checkbox>
														</div>
													</div>
												</div>
											</form>
										</div>
									</mat-tab>
									<mat-tab label="Custom Setup">
										<div
											style="
												height: 100%;
												overflow-y: auto;
											"
										>
											<form
												[formGroup]="form"
												(submit)="submitForm(form)"
											>
												<div
													class="container"
													style="
														margin-top: 10px;
														margin: 0px;
													"
												>
													<div
														class="row"
														style="width: 100%"
													>
														<div
															class="row"
															style="
																margin-top: 10px;
															"
														>
															<h4
																style="
																	text-align: left;
																	font-weight: bold;
																"
															>
																1. Time
																Configuration
															</h4>
															<p
																style="
																	margin-left: 20px;
																"
															>
																Start
																Date/Time:<kendo-datetimepicker
																	(valueChange)="
																		onDateSelectionChange(
																			$event,
																			form,
																			'start'
																		)
																	"
																	[max]="
																		maxDate
																	"
																	[format]="
																		dateTimeFormat
																	"
																	formControlName="startDateTime"
																></kendo-datetimepicker>
															</p>
															<p
																style="
																	margin-left: 20px;
																"
															>
																End
																Date/Time:<kendo-datetimepicker
																	(valueChange)="
																		onDateSelectionChange(
																			$event,
																			form,
																			'end'
																		)
																	"
																	[max]="
																		maxDate
																	"
																	[format]="
																		dateTimeFormat
																	"
																	formControlName="endDateTime"
																></kendo-datetimepicker>
															</p>
														</div>
														<div
															class="row"
															*ngIf="
																sliderDataForWeekSelection.length >
																0
															"
														>
															<p
																style="
																	text-align: left;
																	margin-left: 20px;
																"
															>
																(Optional)
																{{
																	powerTagStdId ===
																	4504
																		? "Power On"
																		: "Ignition"
																}}
																Event Timeline
																({{
																	sliderDataForWeekSelection.length
																}}
																{{
																	powerTagStdId ===
																	4504
																		? "Power On"
																		: "Ignition"
																}}
																events found for
																the past week.)
																<!-- <div class="info-icon">
																	<span class="tooltiptext">This is an Optional Tool that allows you to quickly find times the truck turned on, so you dont have to use the start and end time configuration eact time.</span>
																	<i></i> 
																</div> -->

																<br />
															</p>

															<div
																style="
																	text-align: center;
																"
															>
																<p
																	style="
																		text-align: center;
																	"
																>
																	{{
																		formatLabel()
																	}}
																</p>
															</div>
															<kendo-slider
																*ngIf="
																	sliderDataForWeekSelection.length >
																	0
																"
																style="
																	width: 100% !important;
																	margin-left: 20px;
																"
																[min]="0"
																[max]="
																	sliderDataForWeekSelection.length -
																	1
																"
																[smallStep]="1"
																[(ngModel)]="
																	sliderValue
																"
																(valueChange)="
																	onSliderChange(
																		$event
																	)
																"
															></kendo-slider>
														</div>
														<div
															class="row"
															*ngIf="
																sliderDataForWeekSelection.length ==
																0
															"
															style="
																margin-top: 10px;
															"
														>
															<p>
																No
																{{
																	powerTagStdId ===
																	4504
																		? "Power On"
																		: "Ignition"
																}}
																events found for
																the past week.
																Please use other
																selectors for
																the date range.
															</p>
														</div>

														<div
															*ngIf="
																!isTimeSelectionValid
															"
														>
															<p>
																Cannot retrieve
																playback. Please
																select a
																timeline that is
																no greater than
																4 hours.
															</p>
														</div>
													</div>

													<div
														class="row"
														style="width: 100%"
													>
														<div
															style="
																margin-top: 10px;
															"
															class="form-group"
														>
															<h4
																style="
																	text-align: left;
																	font-weight: bold;
																"
															>
																2. Tags
																Modifications
																(Optional)
															</h4>

															<h6
																style="
																	margin-top: 10px;
																	text-align: left;
																	margin-left: 20px;
																"
															>
																<button
																	class="btn btn-dark-gray"
																	style="
																		display: inline;
																	"
																	(click)="
																		editTags()
																	"
																	type="button"
																>
																	Edit Tags
																</button>
															</h6>
															<p
																style="
																	margin-left: 20px;
																"
															>
																OR
															</p>
															<p
																*ngIf="
																	templatesForUser.length ==
																	0
																"
																style="
																	margin-left: 20px;
																"
															>
																In order to use
																a custom setup
																for playback,
																you need to
																create a
																template.
															</p>

															<!-- <mat-select [(value)]="selectedTemplateId" (selectionChange)="changeTemplate()" *ngIf="templatesForUser.length > 0">
																<mat-option *ngFor="let template of templatesForUser" [value]="template.Id">
																	{{ template.Name }}
																</mat-option>
															</mat-select> -->

															<select
																style="
																	margin-left: 20px;
																"
																class="form-control"
																id="template"
																formControlName="template"
																(change)="
																	changeTemplate()
																"
																*ngIf="
																	templatesForUser.length >
																	0
																"
															>
																<option
																	class="form-control"
																	disabled
																	selected
																	[value]="0"
																>
																	-- select a
																	template --
																</option>
																<option
																	class="form-control"
																	*ngFor="
																		let template of templatesForUser
																	"
																	[value]="
																		template.Id
																	"
																>
																	{{
																		template.Name
																	}}
																</option>
															</select>

															<button
																class="btn btn-dark-gray"
																style="
																	display: inline;
																	margin-left: 20px;
																"
																(click)="
																	createTemplate(
																		form
																	)
																"
																type="button"
																[disabled]="
																	!isTimeSelectionValid
																"
															>
																Create/Edit
																Template
															</button>
															<!-- <button *ngIf="
																	currentTemplate !==
																	undefined
																" class="btn btn-dark-gray" style="display: inline;" (click)="
																	getPlayBackWithTemplate(
																		form
																	)
																" type="button" [disabled]="
																	!isTimeSelectionValid
																">
																Get Playback with
																Template
															</button> -->
														</div>
													</div>

													<div class="row">
														<div>
															<h4
																*ngIf="
																	jbtStandardObservationList.length >
																	0
																"
																style="
																	margin-top: 15px;
																	font-weight: bold;
																	text-align: left;
																"
															>
																3. Configuration
																Summary:
															</h4>
															<h6
																*ngIf="
																	jbtStandardObservationList.length >
																	0
																"
																style="
																	margin-top: 15px;
																	text-align: left;
																	text-decoration: underline;
																	margin-left: 20px;
																"
															>
																Tags:
															</h6>
															<p
																*ngFor="
																	let o of selectedTags
																"
																style="
																	margin-left: 20px;
																"
															>
																{{
																	dataService
																		.cache
																		.jbtStandardObservationsObject[
																		o
																	]?.Name
																}}
															</p>
															<h6
																style="
																	margin-top: 15px;
																	margin-left: 20px;
																	text-decoration: underline;
																"
															>
																Live Data:
															</h6>

															<p
																*ngIf="
																	listeningToSignalRMessages &&
																	isTimeSelectionValid
																"
																style="
																	margin-left: 20px;
																"
															>
																Data will update
																as new events
																come in.
															</p>

															<p
																*ngIf="
																	!listeningToSignalRMessages &&
																	isTimeSelectionValid
																"
																style="
																	margin-left: 20px;
																"
															>
																Data will NOT
																update as new
																events come in.
															</p>
															<button
																class="btn btn-dark-gray"
																style="
																	display: inline;
																	margin-left: 20px;
																"
																(click)="
																	createCanvasGauges(form)
																"
																type="button"
																[disabled]="
																	!isTimeSelectionValid
																"
															>
																Play
															</button>
														</div>
													</div>
												</div>
											</form>
										</div>
									</mat-tab>
								</mat-tab-group>
								<div
									class="container"
									style="
										height: 100%;
										overflow-y: auto;
										margin: 0px;
										padding: 0px;
										max-width: 10000px;
									"
								></div>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Chart" *ngIf="this.realData.length > 0">
						<div
							id="breadcrumbsChart{{ widgetObject?.WidgetId }}"
							class="lone-chart-template"
							style="width: 100%; height: 95%"
						></div>
					</mat-tab>
					<mat-tab label="Details" *ngIf="this.realData.length > 0">
						<div
							[style.width.%]="100"
							style="height: 100%; overflow-y: auto"
						>
							<div
								[style.width.%]="100"
								*ngIf="this.realData?.length > 0"
							>
								<mat-stepper orientation="vertical" #stepper>
									<mat-step
										*ngFor="
											let dataObj of realData;
											index as i
										"
									>
										<ng-template matStepLabel>
											<div #stepsOfStepper>
												{{
													formatLabelDate(
														dataObj.JavascriptDate
													)
												}}
											</div>
										</ng-template>
										<ng-template matStepContent>
											<div
												*ngFor="
													let column of gridSettings.columnsConfig
												"
											>
												<div
													*ngIf="
														column.field !== 'Id' &&
														column.title !== 'Date'
													"
													[style.color]="
														dataObj.Index ===
														sliderValuePlayback - 1
															? 'lightblue'
															: ''
													"
												>
													{{ column.title }}:
													{{ dataObj[column.field] }}
												</div>
											</div>
											<!-- <div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Speed:
						{{ dataObj.Speed }}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Engine Speed:
						{{
							dataObj.EngineSpeed
						}}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Fuel Level:
						{{ dataObj.FuelLevel }}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Power On:
						{{
							dataObj.PowerOnValue
						}}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Highest Alert:
						{{
							dataObj.highestAlert
						}}
					</div> -->
										</ng-template>
									</mat-step>
									<!-- <mat-step>
		  <ng-template matStepLabel>Step 2</ng-template>
		  <ng-template matStepContent>
			<p>This content was also rendered lazily</p>
			<button mat-button matStepperPrevious>Back</button>
			<button mat-button matStepperNext>Next</button>
		  </ng-template>
		</mat-step>
		<mat-step>
		  <ng-template matStepLabel>Step 3</ng-template>
		  <p>This content was rendered eagerly</p>
		  <button mat-button matStepperPrevious>Back</button>
		</mat-step> -->
								</mat-stepper>
								<!-- <p-timeline
			[value]="this.realData"
			align="left"
			styleClass="customized-timeline"
		>
			<ng-template
				pTemplate="content"
				let-dataObj
			>
				<p-card
					[style.color]="
						dataObj.Index ===
						currentDataIndex
							? 'lightblue'
							: 'white'
					"
					[header]="
						dataObj.JavascriptDate?.toLocaleString()
					"
					id="timelineCard{{
						dataObj?.Id
					}}"
					#timelineEvents
				>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Speed:
						{{ dataObj.Speed }}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Engine Speed:
						{{
							dataObj.EngineSpeed
						}}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Fuel Level:
						{{ dataObj.FuelLevel }}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Power On:
						{{
							dataObj.PowerOnValue
						}}
					</div>
					<div
						[style.color]="
							dataObj.Index ===
							currentDataIndex
								? 'lightblue'
								: ''
						"
					>
						Highest Alert:
						{{
							dataObj.highestAlert
						}}
					</div>
				</p-card>
			</ng-template>
		</p-timeline> -->
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Table" *ngIf="this.realData.length > 0">
						<div
							class="parent-table-container"
							[style.height.%]="100"
						>
							<lib-kendo-grid-parent
								*ngIf="realData != undefined"
								[gridData]="realData"
								[gridSettings]="gridSettings"
								[widgetObject]="widgetObject"
								[style.height.%]="100"
								filterable="menu"
								[sortable]="true"
								[reorderable]="true"
								[resizable]="true"
								(onRightClickSelectEmitter)="
									onRightClickSelect($event)
								"
								[currentDataIndex]="sliderValuePlayback - 1"
								#tagDataGrid
							>
							</lib-kendo-grid-parent>
						</div>
					</mat-tab>

					<mat-tab
						[label]="'Gauges'"
						*ngIf="this.realData.length > 0 && shouldShowGauges"
					>
						<div
							style="height: 100%; width: 100%; overflow-y: auto"
						>
							<div
								class="container"
								*ngIf="
									onlyDataColumnConfigs.length === 5 &&
									canvasTemplate === undefined
								"
							>
								<div
									style="
										display: flex;
										justify-content: space-between;
									"
								>
									<div>
										<!-- <kendo-radialgauge
			[pointer]="{
				value: realData[
					sliderValuePlayback - 1
				][onlyDataColumnConfigs[0].field]
			}"
			*ngIf="
				realData.length > 0 &&
				onlyDataColumnConfigs[0].field !== 'Id' &&
				onlyDataColumnConfigs[0].title !== 'Date'
			"
		>
			<kendo-radialgauge-scale
				[minorUnit]="
					onlyDataColumnConfigs[0].minorUnit
				"
				[majorUnit]="
					onlyDataColumnConfigs[0].majorUnit
				"
				[max]="onlyDataColumnConfigs[0].max"
			>
				<kendo-radialgauge-scale-ranges>
					<kendo-radialgauge-scale-range
						[from]="0"
						[to]="5"
						color="#ffc700"
					>
					</kendo-radialgauge-scale-range>
					<kendo-radialgauge-scale-range
						[from]="5"
						[to]="15"
						color="#ff7a00"
					>
					</kendo-radialgauge-scale-range>
					<kendo-radialgauge-scale-range
						[from]="15"
						[to]="25"
						color="#c20000"
					>
					</kendo-radialgauge-scale-range>
				</kendo-radialgauge-scale-ranges>
			</kendo-radialgauge-scale>
		</kendo-radialgauge> -->
										<img
											[style.height.px]="100"
											[src]="
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[0]
														.field
												] == 1
													? global.imagesUrl +
													  'assets/img/gse-images/power-on.svg'
													: global.imagesUrl +
													  'assets/img/gse-images/power-off-' +
													  global.Theme +
													  '.svg'
											"
										/>

										<div
											[style.color]="
												global.Theme === 'dark'
													? '#fff'
													: '#000'
											"
											style="text-align: center"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[0]
													.field !== 'Id' &&
												onlyDataColumnConfigs[0]
													.title !== 'Date'
											"
										>
											{{
												onlyDataColumnConfigs[0].title
											}}:
											{{
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[0]
														.field
												] | decimalPlaces : 1
											}}
										</div>
									</div>
									<div>
										<img
											[style.height.px]="100"
											[src]="
												global.imagesUrl +
												'assets/images/VocationalGraphics/oshkosh-truck.png'
											"
										/>
									</div>
								</div>
								<div
									style="
										display: flex;
										justify-content: space-between;
									"
								>
									<div>
										<kendo-radialgauge
											[pointer]="{
												value: realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[1]
														.field
												]
											}"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[1]
													.field !== 'Id' &&
												onlyDataColumnConfigs[1]
													.title !== 'Date'
											"
										>
											<kendo-radialgauge-scale
												[minorUnit]="
													onlyDataColumnConfigs[1]
														.minorUnit
												"
												[majorUnit]="
													onlyDataColumnConfigs[1]
														.majorUnit
												"
												[max]="
													onlyDataColumnConfigs[1].max
												"
											>
												<kendo-radialgauge-scale-labels
													[color]="
														global.Theme === 'dark'
															? '#fff'
															: '#000'
													"
												>
												</kendo-radialgauge-scale-labels>
												<kendo-radialgauge-scale-ranges>
													<kendo-radialgauge-scale-range
														[from]="3000"
														[to]="3500"
														color="#ff7a00"
													>
													</kendo-radialgauge-scale-range>
													<kendo-radialgauge-scale-range
														[from]="3500"
														[to]="5500"
														color="#c20000"
													>
													</kendo-radialgauge-scale-range>
												</kendo-radialgauge-scale-ranges>
											</kendo-radialgauge-scale>
										</kendo-radialgauge>
										<div
											[style.color]="
												global.Theme === 'dark'
													? '#fff'
													: '#000'
											"
											style="text-align: center"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[1]
													.field !== 'Id' &&
												onlyDataColumnConfigs[1]
													.title !== 'Date'
											"
										>
											{{
												onlyDataColumnConfigs[1].title
											}}:
											{{
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[1]
														.field
												] | decimalPlaces : 1
											}}
										</div>
									</div>
									<div>
										<kendo-lineargauge
											[pointer]="{
												value: realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[2]
														.field
												],
												size: 25,
												color:
													realData[
														sliderValuePlayback - 1
													][
														onlyDataColumnConfigs[2]
															.field
													] >= 50
														? '#3ac738'
														: realData[
																sliderValuePlayback -
																	1
														  ][
																onlyDataColumnConfigs[2]
																	.field
														  ] < 50 &&
														  realData[
																sliderValuePlayback -
																	1
														  ][
																onlyDataColumnConfigs[2]
																	.field
														  ] >= 15
														? '#ff7a00'
														: '#c20000'
											}"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[2]
													.field !== 'Id' &&
												onlyDataColumnConfigs[2]
													.title !== 'Date'
											"
										>
											<kendo-lineargauge-scale
												[minorUnit]="
													onlyDataColumnConfigs[2]
														.minorUnit
												"
												[majorUnit]="
													onlyDataColumnConfigs[2]
														.majorUnit
												"
												[max]="
													onlyDataColumnConfigs[2].max
												"
											>
												<kendo-lineargauge-scale-labels
													[color]="
														global.Theme === 'dark'
															? '#fff'
															: '#000'
													"
												>
												</kendo-lineargauge-scale-labels>
											</kendo-lineargauge-scale>
										</kendo-lineargauge>
										<div
											[style.color]="
												global.Theme === 'dark'
													? '#fff'
													: '#000'
											"
											style="text-align: center"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[2]
													.field !== 'Id' &&
												onlyDataColumnConfigs[2]
													.title !== 'Date'
											"
										>
											{{
												onlyDataColumnConfigs[2].title
											}}:
											{{
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[2]
														.field
												] | decimalPlaces : 1
											}}
										</div>
									</div>
									<div>
										<kendo-radialgauge
											[pointer]="{
												value: realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[3]
														.field
												]
											}"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[3]
													.field !== 'Id' &&
												onlyDataColumnConfigs[3]
													.title !== 'Date'
											"
										>
											<kendo-radialgauge-scale
												[minorUnit]="
													onlyDataColumnConfigs[3]
														.minorUnit
												"
												[majorUnit]="
													onlyDataColumnConfigs[3]
														.majorUnit
												"
												[max]="
													onlyDataColumnConfigs[3].max
												"
											>
												<kendo-radialgauge-scale-labels
													[color]="
														global.Theme === 'dark'
															? '#fff'
															: '#000'
													"
												>
												</kendo-radialgauge-scale-labels>
												<kendo-radialgauge-scale-ranges>
													<kendo-radialgauge-scale-range
														[from]="60"
														[to]="70"
														color="#ff7a00"
													>
													</kendo-radialgauge-scale-range>
													<kendo-radialgauge-scale-range
														[from]="70"
														[to]="80"
														color="#c20000"
													>
													</kendo-radialgauge-scale-range>
												</kendo-radialgauge-scale-ranges>
											</kendo-radialgauge-scale>
										</kendo-radialgauge>
										<div
											[style.color]="
												global.Theme === 'dark'
													? '#fff'
													: '#000'
											"
											style="text-align: center"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[3]
													.field !== 'Id' &&
												onlyDataColumnConfigs[3]
													.title !== 'Date'
											"
										>
											{{
												onlyDataColumnConfigs[3].title
											}}:
											{{
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[3]
														.field
												] | decimalPlaces : 1
											}}
										</div>
									</div>
								</div>
								<div
									style="
										display: flex;
										justify-content: center;
									"
								>
									<div>
										<kendo-lineargauge
											[pointer]="{
												value: realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[4]
														.field
												],
												size: 25,
												color:
													realData[
														sliderValuePlayback - 1
													][
														onlyDataColumnConfigs[4]
															.field
													] >= 50
														? '#3ac738'
														: realData[
																sliderValuePlayback -
																	1
														  ][
																onlyDataColumnConfigs[4]
																	.field
														  ] < 50 &&
														  realData[
																sliderValuePlayback -
																	1
														  ][
																onlyDataColumnConfigs[4]
																	.field
														  ] >= 15
														? '#ff7a00'
														: '#c20000'
											}"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[4]
													.field !== 'Id' &&
												onlyDataColumnConfigs[4]
													.title !== 'Date'
											"
										>
											<kendo-lineargauge-scale
												[minorUnit]="
													onlyDataColumnConfigs[4]
														.minorUnit
												"
												[majorUnit]="
													onlyDataColumnConfigs[4]
														.majorUnit
												"
												[max]="
													onlyDataColumnConfigs[4].max
												"
											>
												<kendo-lineargauge-scale-labels
													[color]="
														global.Theme === 'dark'
															? '#fff'
															: '#000'
													"
												>
												</kendo-lineargauge-scale-labels>
											</kendo-lineargauge-scale>
										</kendo-lineargauge>
										<div
											[style.color]="
												global.Theme === 'dark'
													? '#fff'
													: '#000'
											"
											style="text-align: center"
											*ngIf="
												realData.length > 0 &&
												onlyDataColumnConfigs[4]
													.field !== 'Id' &&
												onlyDataColumnConfigs[4]
													.title !== 'Date'
											"
										>
											{{
												onlyDataColumnConfigs[4].title
											}}:
											{{
												realData[
													sliderValuePlayback - 1
												][
													onlyDataColumnConfigs[4]
														.field
												] | decimalPlaces : 1
											}}
										</div>
									</div>
								</div>
							</div>
							<div
								class="gridster-container"
								*ngIf="canvasTemplate !== undefined"
							>
								<gridster [options]="options">
									<gridster-item
										style="height: 100%"
										[item]="widget"
										*ngFor="
											let widget of canvasTemplate.canvasWidgets
										"
									>
										<div
											style="height: 300px"
											class="card card-tasks widget-card"
											id="widget-{{ widget.Id }}"
										>
											<div
												*ngIf="
													widget.AngularDirectiveName !=
														'gaugeDataDisplay' &&
													widget.AngularDirectiveName !=
														'singleTagValueDisplay'
												"
												style="
													display: flex;
													flex-direction: row;
												"
												class="card-header widget-card-header"
												id="widget-header-{{
													widget.WidgetId
												}}"
												style="padding-top: 0px"
											>
												<h6
													class="title"
													style="
														padding-top: 15px;
														display: inline-block;
													"
												>
													{{ widget.Name }}
												</h6>
											</div>
											<div
												*ngIf="
													widget.AngularDirectiveName ==
													'gaugeDataDisplay'
												"
												style="
													display: flex;
													flex-direction: row;
												"
												class="card-header widget-card-header"
												id="widget-header-{{
													widget.WidgetId
												}}"
												style="padding-top: 0px"
											>
												<h6
													class="title"
													style="
														padding-top: 15px;
														display: inline-block;
													"
												>
													{{ widget.WidgetName }}
												</h6>
											</div>
											<div
												*ngIf="
													widget.AngularDirectiveName ==
													'singleTagValueDisplay'
												"
												style="
													display: flex;
													flex-direction: row;
												"
												class="card-header widget-card-header"
												id="widget-header-{{
													widget.WidgetId
												}}"
												style="padding-top: 0px"
											>
												<h6
													class="title"
													style="
														padding-top: 15px;
														display: inline-block;
													"
												>
													{{ widget.WidgetName }}
												</h6>
											</div>
											<div
												*ngIf="
													widget.AngularDirectiveName ==
														'gaugeDataDisplay' &&
													widget.CanvasWidgetTemplateGaugeTypeId ==
														1 &&
													onlyDataColumnConfigs[
														widget
															.JBTStandardObservationId
													] != null
												"
												class="card-body widget-card-body"
												id="widget-body-{{ widget.Id }}"
											>
												<div
													style="
														height: 100%;
														width: 100%;
													"
													id="{{
														widget.AngularDirectiveName
													}}{{ widget.WidgetId }}"
												>
													<kendo-radialgauge
														[pointer]="{
															value: realData[
																sliderValuePlayback -
																	1
															][
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].field
															]
														}"
													>
														<kendo-radialgauge-scale
															[majorTicks]="{
																color:
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
															}"
															[minorTicks]="{
																color:
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
															}"
															[minorUnit]="
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].minorUnit
															"
															[majorUnit]="
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].majorUnit
															"
															[max]="
																widget.MaxValue !==
																0
																	? widget.MaxValue
																	: onlyDataColumnConfigs[
																			widget
																				.JBTStandardObservationId
																	  ].max
															"
														>
															<kendo-radialgauge-scale-labels
																[color]="
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
																"
															>
															</kendo-radialgauge-scale-labels>
															<kendo-radialgauge-scale-ranges>
																<kendo-radialgauge-scale-range
																	*ngFor="
																		let range of widget.GaugeColorRanges
																	"
																	[from]="
																		range.RangeMinValue
																	"
																	[to]="
																		range.RangeMaxValue
																	"
																	[color]="
																		range.HexColor
																	"
																>
																</kendo-radialgauge-scale-range>
															</kendo-radialgauge-scale-ranges>
														</kendo-radialgauge-scale>
													</kendo-radialgauge>

													<div
														[style.color]="
															global.Theme ===
															'dark'
																? '#fff'
																: '#000'
														"
														style="
															text-align: center;
														"
														*ngIf="
															realData.length >
																0 &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].field !== 'Id' &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title !== 'Date'
														"
													>
														{{
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title
														}}:
														{{
															realData[
																sliderValuePlayback -
																	1
															][
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].field
															]
																| decimalPlaces
																	: 1
														}}
													</div>
												</div>
											</div>

											<div
												*ngIf="
													widget.AngularDirectiveName ==
														'gaugeDataDisplay' &&
													widget.CanvasWidgetTemplateGaugeTypeId ==
														2 &&
													onlyDataColumnConfigs[
														widget
															.JBTStandardObservationId
													] != null
												"
												class="card-body widget-card-body"
												id="widget-body-{{ widget.Id }}"
											>
												<div
													style="
														height: 100%;
														width: 100%;
													"
													id="{{
														widget.AngularDirectiveName
													}}{{ widget.WidgetId }}"
												>
													<kendo-lineargauge>
														<kendo-lineargauge-scale
															[majorTicks]="{
																color:
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
															}"
															[minorTicks]="{
																color:
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
															}"
															[minorUnit]="
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].minorUnit
															"
															[majorUnit]="
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].majorUnit
															"
															[max]="
																widget.MaxValue !==
																0
																	? widget.MaxValue
																	: onlyDataColumnConfigs[
																			widget
																				.JBTStandardObservationId
																	  ].max
															"
														>
															<kendo-lineargauge-scale-labels
																[color]="
																	global.Theme ===
																	'dark'
																		? '#fff'
																		: '#000'
																"
															>
															</kendo-lineargauge-scale-labels>
															<kendo-lineargauge-scale-ranges>
																<kendo-lineargauge-scale-range
																	*ngFor="
																		let range of widget.GaugeColorRanges
																	"
																	[from]="
																		range.RangeMinValue
																	"
																	[to]="
																		range.RangeMaxValue
																	"
																	[color]="
																		range.HexColor
																	"
																>
																</kendo-lineargauge-scale-range>
															</kendo-lineargauge-scale-ranges>
														</kendo-lineargauge-scale>

														<kendo-lineargauge-pointers>
															<kendo-lineargauge-pointer
																[value]="
																	realData[
																		sliderValuePlayback -
																			1
																	][
																		onlyDataColumnConfigs[
																			widget
																				.JBTStandardObservationId
																		].field
																	]
																"
																color="#B22222"
																shape="arrow"
																[margin]="10"
															>
															</kendo-lineargauge-pointer>
														</kendo-lineargauge-pointers>
													</kendo-lineargauge>

													<div
														[style.color]="
															global.Theme ===
															'dark'
																? '#fff'
																: '#000'
														"
														style="
															text-align: center;
														"
														*ngIf="
															realData.length >
																0 &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].field !== 'Id' &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title !== 'Date'
														"
													>
														{{
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title
														}}:
														{{
															realData[
																sliderValuePlayback -
																	1
															][
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].field
															]
																| decimalPlaces
																	: 1
														}}
													</div>
												</div>
											</div>

											<div
												*ngIf="
													widget.AngularDirectiveName ==
														'singleTagValueDisplay' &&
													onlyDataColumnConfigs[
														widget
															.JBTStandardObservationId
													] != null
												"
												class="card-body widget-card-body"
												id="widget-body-{{ widget.Id }}"
											>
												<div
													style="
														height: 100%;
														width: 100%;
														text-align: center;
													"
													id="{{
														widget.AngularDirectiveName
													}}{{ widget.WidgetId }}"
												>
													<svg
														[ngStyle]="{
															fill:
																realData[
																	sliderValuePlayback -
																		1
																][
																	onlyDataColumnConfigs[
																		widget
																			.JBTStandardObservationId
																	].field
																] ==
																widget.ValueWhenActive
																	? widget.HexColorWhenActive
																	: 'grey'
														}"
														height="100px"
														width="100px"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 455.881 455.881"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														enable-background="new 0 0 455.881 455.881"
													>
														<g
															id="SVGRepo_bgCarrier"
															stroke-width="0"
														></g>
														<g
															id="SVGRepo_tracerCarrier"
															stroke-linecap="round"
															stroke-linejoin="round"
														></g>
														<g
															id="SVGRepo_iconCarrier"
														>
															<g>
																<path
																	d="m321.218,257.555c15.516-20.46 23.717-44.881 23.717-70.624 0-31.563-12.379-61.151-34.857-83.314-22.474-22.158-52.261-34.124-83.828-33.668-30.591,0.433-59.412,12.708-81.154,34.564-21.741,21.855-33.867,50.741-34.145,81.335-0.238,26.228 8.011,51.088 23.855,71.894 25.721,33.776 39.887,75.149 39.887,116.498v45.244c0,20.069 16.327,36.396 36.396,36.396h33.854c20.069,0 36.396-16.328 36.396-36.396v-45.241c0.001-41.806 13.691-82.157 39.879-116.688zm-174.478-8.9c-13.808-18.132-20.997-39.803-20.79-62.67 0.499-54.935 45.588-100.26 100.512-101.037 27.553-0.37 53.493,10.035 73.084,29.352 19.597,19.321 30.389,45.116 30.389,72.632 0,22.442-7.147,43.729-20.669,61.56-27.593,36.385-42.45,78.833-43.058,122.93h-76.536c-0.627-43.669-15.817-87.161-42.932-122.767zm42.953,165.746v-6.072l76.647,8.781v2.374c0,1.25-0.13,2.468-0.336,3.659l-76.311-8.742zm0-21.17v-6.809h76.647v15.59l-76.647-8.781zm21.396,47.65c-8.057,0-15.082-4.48-18.731-11.077l64.567,7.397c-3.421,2.321-7.545,3.679-11.981,3.679h-33.855z"
																></path>
																<path
																	d="m153.776,198.213c-0.585-3.925-0.864-7.957-0.827-11.983 0.038-4.142-3.289-7.53-7.431-7.568-4.114-0.036-7.53,3.289-7.568,7.431-0.044,4.81 0.289,9.632 0.99,14.333 0.555,3.722 3.755,6.395 7.409,6.395 0.368,0 0.741-0.027 1.116-0.083 4.096-0.612 6.922-4.428 6.311-8.525z"
																></path>
																<path
																	d="m197.913,312.135c0.776,0 1.566-0.122 2.344-0.377 3.935-1.294 6.076-5.533 4.782-9.467-8.312-25.277-20.7-48.827-36.82-69.994-2.664-3.499-5.025-7.226-7.016-11.079-1.902-3.68-6.427-5.12-10.107-3.218-3.679,1.902-5.12,6.427-3.218,10.107 2.39,4.622 5.218,9.089 8.408,13.278 15.106,19.836 26.715,41.904 34.504,65.591 1.038,3.157 3.971,5.159 7.123,5.159z"
																></path>
																<path
																	d="m306.746,212.613c-3.804-1.639-8.217,0.117-9.855,3.921-2.376,5.518-5.451,10.781-9.139,15.643-2.503,3.3-1.856,8.005 1.444,10.508 1.355,1.028 2.947,1.524 4.526,1.524 2.267,0 4.507-1.023 5.982-2.969 4.419-5.827 8.107-12.143 10.963-18.772 1.639-3.804-0.116-8.217-3.921-9.855z"
																></path>
																<path
																	d="m227.941,111.938c41.352,0 74.994,33.643 74.994,74.995 0,2.351-0.108,4.72-0.321,7.041-0.378,4.125 2.658,7.775 6.783,8.154 0.233,0.021 0.464,0.032 0.694,0.032 3.833,0 7.103-2.923 7.46-6.815 0.254-2.775 0.384-5.605 0.384-8.412 0-49.623-40.371-89.995-89.994-89.995-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5z"
																></path>
																<path
																	d="m227.941,57c4.142,0 7.5-3.358 7.5-7.5v-42c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v42c0,4.142 3.358,7.5 7.5,7.5z"
																></path>
																<path
																	d="m152.065,71.82c1.39,2.407 3.91,3.752 6.502,3.752 1.272,0 2.562-0.324 3.743-1.006 3.587-2.071 4.817-6.658 2.746-10.245l-20.99-36.36c-2.072-3.588-6.658-4.817-10.245-2.746-3.587,2.071-4.817,6.658-2.746,10.245l20.99,36.36z"
																></path>
																<path
																	d="m388.22,272.115l-36.36-20.99c-3.588-2.072-8.175-0.842-10.245,2.746-2.071,3.587-0.842,8.174 2.746,10.245l36.36,20.99c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752 2.071-3.587 0.841-8.174-2.746-10.245z"
																></path>
																<path
																	d="m67.661,104.366l36.36,20.99c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752 2.071-3.587 0.842-8.174-2.746-10.245l-36.36-20.99c-3.587-2.072-8.174-0.842-10.245,2.746-2.071,3.587-0.842,8.173 2.746,10.245z"
																></path>
																<path
																	d="m408.68,180.74h-42c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h42c4.142,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"
																></path>
																<path
																	d="m96.7,188.24c0-4.142-3.358-7.5-7.5-7.5h-42c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h42c4.143,0 7.5-3.358 7.5-7.5z"
																></path>
																<path
																	d="m348.117,126.362c1.272,0 2.562-0.324 3.743-1.006l36.36-20.99c3.587-2.071 4.817-6.658 2.746-10.245-2.071-3.587-6.659-4.817-10.245-2.746l-36.36,20.99c-3.587,2.071-4.817,6.658-2.746,10.245 1.389,2.406 3.911,3.752 6.502,3.752z"
																></path>
																<path
																	d="m104.021,251.125l-36.36,20.99c-3.587,2.071-4.817,6.658-2.746,10.245 1.389,2.406 3.91,3.752 6.502,3.752 1.272,0 2.562-0.324 3.743-1.006l36.36-20.99c3.587-2.071 4.817-6.658 2.746-10.245-2.07-3.588-6.66-4.817-10.245-2.746z"
																></path>
																<path
																	d="m293.571,74.566c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752l20.99-36.36c2.071-3.587 0.841-8.174-2.746-10.245-3.586-2.071-8.174-0.842-10.245,2.746l-20.99,36.36c-2.071,3.587-0.841,8.174 2.746,10.245z"
																></path>
															</g>
														</g>
													</svg>

													<div
														[style.color]="
															global.Theme ===
															'dark'
																? '#fff'
																: '#000'
														"
														style="
															text-align: center;
														"
														*ngIf="
															realData.length >
																0 &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].field !== 'Id' &&
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title !== 'Date'
														"
													>
														{{
															onlyDataColumnConfigs[
																widget
																	.JBTStandardObservationId
															].title
														}}:
														{{
															realData[
																sliderValuePlayback -
																	1
															][
																onlyDataColumnConfigs[
																	widget
																		.JBTStandardObservationId
																].field
															]
														}}
													</div>
												</div>
											</div>
										</div>
									</gridster-item>
								</gridster>
							</div>
						</div>
					</mat-tab>

					<mat-tab
						[label]="'Summary'"
						*ngIf="this.realData.length > 0"
					>
						<div
							*ngIf="dataDisplay"
							[style.height.%]="100"
							[style.width.%]="100"
						>
							<table
								mat-table
								[dataSource]="operationalDetails[0].records"
								class="mat-elevation-z8"
							>
								<!--- Note that these columns can be defined in any order.
									  The actual rendered columns are set as a property on the row definition" -->

								<!-- Position Column -->
								<ng-container matColumnDef="FinalName">
									<th mat-header-cell *matHeaderCellDef>
										Asset Tags for Timeframe
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.FinalName }}
									</td>
								</ng-container>

								<!-- Name Column -->
								<ng-container matColumnDef="FinalValue">
									<th mat-header-cell *matHeaderCellDef>
										Value
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.FinalValue }}
									</td>
								</ng-container>

								<tr
									mat-header-row
									*matHeaderRowDef="displayedColumns"
								></tr>
								<tr
									mat-row
									*matRowDef="
										let row;
										columns: displayedColumns
									"
								></tr>
							</table>
							<table
								mat-table
								[dataSource]="operationalDetails[1].records"
								class="mat-elevation-z8"
							>
								<!--- Note that these columns can be defined in any order.
									  The actual rendered columns are set as a property on the row definition" -->

								<!-- Position Column -->
								<ng-container matColumnDef="FinalName">
									<th mat-header-cell *matHeaderCellDef>
										Lifetime Asset Tags
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.FinalName }}
									</td>
								</ng-container>

								<!-- Name Column -->
								<ng-container matColumnDef="FinalValue">
									<th mat-header-cell *matHeaderCellDef>
										Value
									</th>
									<td mat-cell *matCellDef="let element">
										{{ element.FinalValue }}
									</td>
								</ng-container>

								<tr
									mat-header-row
									*matHeaderRowDef="displayedColumns"
								></tr>
								<tr
									mat-row
									*matRowDef="
										let row;
										columns: displayedColumns
									"
								></tr>
							</table>
							<!-- <div [style.height.%]="50">
									<div>


										<div>
											<table
												style="
													border: 2px solid green;
													margin: 0px;
												"
											>
												<tr>
													<th class="thstyle">
														Hrs Driven
													</th>
													<th class="thstyle">
														Total Drive Hrs
													</th>
													<th class="thstyle">
														Idle Hrs
													</th>
													<th class="thstyle">
														Total Idle Hrs
													</th>
												</tr>
												<tr>
													<th></th>
												</tr>
												<tr>
													<td class="trstyle">
														{{
															operationalDetails.driveTimeInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.totalDriveTimeInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.idleHrsInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.totalIdleHrsInfo
														}}
													</td>
												</tr>
												<tr>
													<th class="thstyle">
														Fuel Used (Ltrs)
													</th>
													<th class="thstyle">
														Total Fuel Used(Ltrs)
													</th>
													<th class="thstyle">
														Hours Operated
													</th>
													<th class="thstyle">
														Total Operated Hrs
													</th>
												</tr>
												<tr>
													<th></th>
												</tr>
												<tr>
													<td class="trstyle">
														{{
															operationalDetails.fuelUsedInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.totalfuelUsedInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.operateTimeHrsInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.totalOperateHrsInfo
														}}
													</td>
												</tr>
											</table>
											<br />
											<table
												style="
													border: 2px solid green;
													margin: 0px;
												"
											>
												<tr>
													<th class="thstyle">
														Engine On Hrs
													</th>
													<th class="thstyle">
														Miles Driven
													</th>
													<th class="thstyle">
														Charge Lev
													</th>
													<th class="thstyle">
														Used Hrs
													</th>
												</tr>
												<tr>
													<th></th>
												</tr>
												<tr>
													<td class="trstyle">
														{{
															operationalDetails.engineOnHrsInfo
														}}
													</td>
													<td class="trstyle">
														{{
															operationalDetails.milesDrivenInfo
														}}
													</td>
													<td class="trstyle">-</td>
													<td class="trstyle">-</td>
												</tr>
											</table>
										</div>
									</div>

								</div> -->
						</div>
					</mat-tab>

					<mat-tab
						label="Playback Faults -  {{ historicAlarms?.length }}"
						*ngIf="this.realData.length > 0"
					>
						<div
							style="display: flex; flex-flow: column"
							[style.height.%]="100"
						>
							<div class="flexContent">
								<lib-kendo-grid-parent
									*ngIf="historicAlarms.length > 0"
									[gridData]="historicAlarms"
									[gridSettings]="alarmGridSettings"
									[widgetObject]="widgetObject"
									[style.height.%]="100"
									filterable="menu"
									[sortable]="true"
									[reorderable]="true"
									[resizable]="true"
									(onRightClickSelectEmitter)="
										onRightClickSelect($event)
									"
									#alarmDataGrid
								>
								</lib-kendo-grid-parent>
							</div>
						</div>
					</mat-tab>

					<mat-tab
						label="Playback Alerts -  {{ historicAlerts?.length }}"
						*ngIf="this.realData.length > 0"
					>
						<div
							style="display: flex; flex-flow: column"
							[style.height.%]="100"
						>
							<div class="flexContent">
								<lib-kendo-grid-parent
									*ngIf="historicAlerts.length > 0"
									[gridData]="historicAlerts"
									[gridSettings]="alarmGridSettings"
									[widgetObject]="widgetObject"
									[style.height.%]="100"
									filterable="menu"
									[sortable]="true"
									[reorderable]="true"
									[resizable]="true"
									(onRightClickSelectEmitter)="
										onRightClickSelect($event)
									"
									#alertDataGrid
								>
								</lib-kendo-grid-parent>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
