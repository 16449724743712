import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	Optional,
	Inject,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GroupDescriptor } from '@progress/kendo-data-query';

import { of, from } from 'rxjs';
import {
	map,
	filter,
	tap,
	groupBy,
	mergeMap,
	reduce,
	toArray,
	switchMap,
} from 'rxjs/operators';
import _ from 'lodash';

import { process } from '@progress/kendo-data-query';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { AlertManagementPopupComponent } from '../alert-management-popup/alert-management-popup.component';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { UtilityService } from '../../services/utility.service';

@Component({
	selector: 'lib-alert-control-panel',
	templateUrl: './alert-control-panel.component.html',
	styleUrls: ['./alert-control-panel.component.scss'],
})
export class AlertControlPanelComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('alertControlPanelGrid') alertControlPanelGrid: KendoGridParentComponent;

	dbEmailTagUsersUnfiltered: any;
	alertSummary: any;
	alertSummaryCount: any;

	public dataCollections: any = {
		daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		hoursOfDay00to11: _.range(0, 12).filter((digit) => {
			return digit < 12;
		}),
		hoursOfDay12to23: _.range(12, 24).filter((digit) => {
			return digit < 24;
		}),
		category: ['criticalSite', 'allPBBCriticalSite', 'allPCACriticalSite', 'allGPUCriticalSite', 'criticalGateSystem', 'criticalAsset',
			'site', 'allPBBSite', 'allPCASite',  'allGPUSite', 'gateSystem',  'asset', 'alertAsset',
			'tag', 'JbtStandardObservation', 'allGSECriticalSite', 'allGSESite', 'CriticalGSEAssetType', 'GSEAssetType', 'CriticalGSEAssetModel', 'GSEAssetModel'
			, 'CriticalThing', 'AlertThing', 'Thing'],
	};

	public gridContextMenuItems = ["Edit Alert Notification Settings"];
	displayedColumns: string[] = ['alertType', 'category', 'text', 'notificationActive'];
	dataSource: Object[];
	widgetTypeGroups: any;
	isLoading: boolean;
	theme: string;
	public gridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},

		},
		columnsConfig: [
			{
				field: 'alertType',
				title: 'Alert Type',
				filterable: true,
				_width: 100,
				hidden: false,
				minResizableWidth: 50,
			},
			{
				field: 'assetGroupType',
				title: 'Asset Group Type',
				filterable: true,
				_width: 100,
				minResizableWidth: 50,
			},
			{
				field: 'text',
				title: 'Asset Group',
				filterable: true,
				_width: 250,
				minResizableWidth: 50,
			},
			{
				field: 'details',
				title: 'Details',
				filterable: true,
				_width: 250,
				minResizableWidth: 50,
			},
			{
				field: 'wait',
				title: 'Wait Time (mins)',
				filterable: true,
				_width: 75,
				minResizableWidth: 50,
			},
			{
				field: 'notificationActive',
				title: 'Configured To Send',
				filterable: true,
				_width: 75,
				minResizableWidth: 50,
			},
			{
				field: 'EditAlert',
				title: 'Actions',
				filterable: true,
				_width: 75,
				minResizableWidth: 50,
			},
		],
	};


	public assetTypes: any = [];
	public assetModels: any = [];
	private fleetAssets:any;

	colorChangedSubscription: any;
	public gridData: any[];
	gridView: any;

	private signalRAlerts$: any;
	private signalRSubscription: any;

	constructor(
		public dataService: DataService,
		public dialogRef: MatDialogRef<AlertControlPanelComponent>,
		public dialog: MatDialog,
		private signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
	) {}

	ngOnInit() {
		var service = this;

		console.log(Global);
		if (Global.Theme === 'light') {
			service.theme = 'light';
		} else if (Global.Theme === 'dark') {
			service.theme = 'dark';
		}

		service.colorChangedSubscription =
			service.dataService.colorChanged$.subscribe((data: any) => {
				Global.User.DebugMode && console.log(data);
				if (data === 'light') {
					service.theme = 'light';
				} else {
					service.theme = 'dark';
				}
			});
		service.isLoading = true;




	}

	ngAfterViewInit(): void {

		this.gridData = [];



		this.dataService.GetFleets().subscribe((fleets) => {
			// load the user alerts
			this.dataService.GetFleetAssets().subscribe((assets) => {
				this.fleetAssets = assets;
				this.LoadData();
			});
		});

		var taguseralertgroup = "TUA-" + this.dataService.currentUserJSONObject.Email;
		this.signalRCore.joinAdditionalGroup(taguseralertgroup);
		this.signalRAlerts$ = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) => msg.code == "email-tag-users"))
			.subscribe((signalRString: any) => {
				// process message
				console.log("email-tag-users change ", signalRString?.object);

				this.LoadData();
		});

		this.signalRSubscription = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) =>
			msg.code == "dataService.cache.emailTagUsers Added" ||
			msg.code == "dataService.cache.emailTagUsers Modified" ||
			msg.code == "dataService.cache.emailTagUsers Deleted"
			)).subscribe((msg: any) => {
				let objectId = msg.object;
				console.log("emailTagUsers change ", msg.object);

				this.LoadData();
		});

		this.isLoading = false;
	}

	LoadData() {
		this.GetAssetTypesAndModels();

		this.dataService
			.SQLActionAsPromise(
				'API.RetrieveEmailTagUsersRecordsByUserId ' +
					Global.User.currentUser.Id
			)
			.then((records: any) => {
				console.log('email tag user records:', records);

				// reload when alerts have changed
				let index = this.gridData.length - 1;

				while (index >= 0) {
					this.gridData.splice(index, 1);
					index -= 1;
				}

				this.dbEmailTagUsersUnfiltered = records;

				this.GenerateSelectionsSummary();

				this.alertControlPanelGrid.gridDataSubject.next(this.gridData);

			});

	}


	GenerateSelectionsSummary()
	{
		this.alertSummary = [];
		var alertSummaryTemp = [];

		this.dbEmailTagUsersUnfiltered.forEach(alert =>
			{
				this.dataCollections.category.forEach(category => {
					var capitalizedCategory = category.substring(0, 1).toUpperCase() + category.substring(1);

					var alertCategoryId = alert[capitalizedCategory + 'Id'];
					var summary = {
						alertType: "",
						assetGroupType: "",
						category: "",
						text: "",
						details: "",
						id: parseInt(alertCategoryId),
						siteId: null,
						fleetId: null,
						notificationActive: "warning",
						etu: alert,
						capitalizedCategory: capitalizedCategory,
						wait: 15,
					};
					if(alertCategoryId != undefined) {
						console.log('alertCat', alertCategoryId);
						if (capitalizedCategory == "GateSystem") {
							var gate = this.dataService.cache.systems.find((gate) => {
								return gate.Id == alertCategoryId;
							});
							if(gate != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'gate';
								summary.assetGroupType = 'Gate';
								summary.text = 'Site ' + gate.Site.Name + ' Gate ' + gate.Name;

							}
							else {
								console.log("Warning: GateSystem does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalGateSystem") {
							var gate = this.dataService.cache.systems.find((gate) => {
								return gate.Id == alertCategoryId;
							});
							if(gate != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'gate';
								summary.assetGroupType = 'Gate';
								summary.text = 'Site ' + gate.Site.Name + ' Gate ' + gate.Name;

							}
							else {
								console.log("Warning: CriticalGateSystem does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Asset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								
								summary.category = 'asset';
								summary.assetGroupType = 'Asset';

								let fleetAsset = this.fleetAssets.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									summary.alertType = 'Alarm';
									summary.text = 'Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									summary.alertType = 'Amber';
									summary.text = 'Asset ' + fleetAsset.FleetName + '-' + asset.Name;
									summary.fleetId = fleetAsset.FleetId;
								}

							}
							else {
								console.log("Warning: Asset does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalAsset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								
								summary.category = 'asset';
								summary.assetGroupType = 'Asset';

								let fleetAsset = this.fleetAssets.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									summary.alertType = 'Critical';
									summary.text = 'Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									summary.alertType = 'Red';
									summary.text = 'Asset ' + fleetAsset.FleetName + '-' + asset.Name;
									summary.fleetId = fleetAsset.FleetId;

								}
							}
							else {
								console.log("Warning: CriticalAsset does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AlertAsset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								
								summary.category = 'asset';
								summary.assetGroupType = 'Asset';

								let fleetAsset = this.fleetAssets.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									summary.alertType = 'Critical';
									summary.text = 'Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									summary.alertType = 'Alert';
									summary.text = 'Asset ' + fleetAsset.FleetName + '-' + asset.Name;
									summary.fleetId = fleetAsset.FleetId;

								}
							}
							else {
								console.log("Warning: AlertAsset does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Tag") {

							summary.alertType = alert.TagEffectiveSeverityLevel;
							summary.category = 'tag';
							summary.assetGroupType = 'Asset';

							let fleetAsset = this.fleetAssets.find((a) => a.Id == alert.TagAssetId);
							if(fleetAsset == null) {
								summary.text = alert.TagSiteName + '-' + alert.TagGateName + '-' + alert.TagAssetName + '-' + alert.TagStandardName;
							}
							else {
								summary.text = fleetAsset.FleetName + '-' + alert.TagAssetName + '-' + alert.TagStandardName;
							}

						} else if (capitalizedCategory == "JbtStandardObservation" && alert['JbtStandardObservationSiteId'] != null) {
							var so = this.dataService.cache.jbtStandardObservations.find((so) => {
								return so.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(so != undefined && site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'tag';
								summary.assetGroupType = 'Site';
								summary.text = site.Name + '-' + so.Name;

								summary.siteId = site.Id;
							}
							else {
								console.log("Warning: stdobs or site does not exist. stdobs= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "JbtStandardObservation" && alert['GroupThingId'] != null) {
							var so = this.dataService.cache.jbtStandardObservations.find((so) => {
								return so.Id == alertCategoryId;
							});
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId == alert['GroupThingId'] );
									});

									if(so != undefined && fleet != undefined) {
										summary.alertType = so.JBTStandardObservationSeverityLevel.Name;
										summary.category = 'tag';
										summary.assetGroupType = 'Fleet';
										summary.text = fleet.FleetName + '-' + so.Name;
										summary.fleetId = fleet.FleetId;
									}
									else {
										console.log("Warning: stdobs or site does not exist. stdobs= ", alertCategoryId +  "fleet= %O", alert['GroupThingId']);
									}
								}
							});

						} else if (capitalizedCategory == "CriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'site';
								summary.assetGroupType = 'Site';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: CriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Site") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'site';
								summary.assetGroupType = 'Site';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: Site does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalThing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Critical';
										summary.category = 'fleet';
										summary.assetGroupType = 'Fleet';
										summary.text = 'Fleet ' + fleet.FleetName
									}
									else {
										console.log("Warning: CriticalFleet does not exist ", alertCategoryId);
									}
								}

							});

						} else if (capitalizedCategory == "AlertThing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Alert';
										summary.category = 'fleet';
										summary.assetGroupType = 'Fleet';
										summary.text = 'Fleet ' + fleet.FleetName
									}
									else {
										console.log("Warning: AlertFleet does not exist ", alertCategoryId);
									}
								}

							});

						} else if (capitalizedCategory == "Thing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet =  data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Amber';
										summary.category = 'fleet';
										summary.assetGroupType = 'Fleet';
										summary.text = 'Fleet ' + fleet.FleetName;
									}
									else {
										console.log("Warning: fleet does not exist ", alertCategoryId);
									}

								}

							});

						} else if (capitalizedCategory == "AllPBBCriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'PBB';
								summary.assetGroupType = 'PBB';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllPBBCriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPBBSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'PBB';
								summary.assetGroupType = 'PBB';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllPBBSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPCACriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'PCA';
								summary.assetGroupType = 'PCA';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCACriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPCASite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'PCA';
								summary.assetGroupType = 'PCA';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCASite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGPUCriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GPU';
								summary.assetGroupType = 'GPU';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCASite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGPUSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'GPU';
								summary.assetGroupType = 'GPU';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllGPUSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGSECriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE';
								summary.assetGroupType = 'GSE';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllGSECriticalSite does not exist ", alertCategoryId);
							}


						} else if (capitalizedCategory == "AllGSESite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'GSE';
								summary.assetGroupType = 'GSE';
								summary.text = 'Site ' + site.Name
							}
							else {
								console.log("Warning: AllGSESite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalGSEAssetType") {
							var type = this.assetTypes.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(type != undefined && site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE Asset Type';
								summary.assetGroupType = 'GSE Asset Type';
								summary.text = 'GSE Asset Types ' + site.Name + '-' + type.Name;
							}
							else {
								console.log("Warning: CriticalGSEAssetType or site does not exist. type= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "GSEAssetType") {
							var type = this.assetTypes.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(type != undefined && site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'GSE Asset Type';
								summary.assetGroupType = 'GSE Asset Type';
								summary.text = 'GSE Asset Types ' + site.Name + '-' + type.Name;
							}
							else {
								console.log("Warning: GSEAssetType or site does not exist. type= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}


						} else if (capitalizedCategory == "CriticalGSEAssetModel") {
							var model = this.assetModels.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(model != undefined && site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE Asset Model';
								summary.assetGroupType = 'GSE Asset Model';
								summary.text = 'GSE Asset Model ' + site.Name + '-' + model.SelectorName;
							}
							else {
								console.log("Warning: CriticalGSEAssetModel or site does not exist. model= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "GSEAssetModel") {
							var model = this.assetModels.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(model != undefined && site != undefined) {
								summary.alertType = 'Alarm';
								summary.category = 'GSE Asset Model';
								summary.assetGroupType = 'GSE Asset Model';
								summary.text = 'GSE Asset Model ' + site.Name + '-' + model.SelectorName;
							}
							else {
								console.log("Warning: GSEAssetModel or site does not exist. model= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						}


						summary.notificationActive = this.IsConfiguredToSendOutNotifications(alert);
						summary.details = this.SelectedTextForAlertDescription(alert);
						summary.wait = alert.MinutesBetweenNotification == null ? 15 : alert.MinutesBetweenNotification;


						if(summary.text != '') {
							alertSummaryTemp.push(summary);
						}
					}
					//console.log('alertCat', alertCat);
				});


			});

		//this.alertSummary = alertSummaryTemp.concat(this.alertSummaryFromOtherSites).toArray();
		this.alertSummary = alertSummaryTemp;

		this.alertSummary.forEach(element => {
			this.gridData.push(element);
		});


		this.alertSummaryCount = this.alertSummary.length;
	}


	IsConfiguredToSendOutNotifications(alert) : string {

		var isDays = this.dataCollections.daysOfWeek.some((dayOfWeek) => { return alert['Send' + dayOfWeek] == true;});
		var isHours = this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).some((hourOfDay) => {
			var hourAsTwoDigitString = hourOfDay.toString().padStart(2, "0");
			return alert["SendHour" + hourAsTwoDigitString] == true;});

		return (alert.Email || alert.TextMessage) && (alert.SendAllDays || isDays) && (alert.SendAllHours || isHours) ? 'success' : 'normal';
	}


	SelectedTextForAlertDescription(alert) : string
	{
		var SelectionsForPanelDescription = [];
		var DaysSelectionsForPanelDescription = [];
		var HoursSelectionsForPanelDescription = [];



		//this.buttonSelections[category].MinutesBetweenNotification[id] ? SelectionsForPanelDescription.push("Wait " + this.buttonSelections[category].MinutesBetweenNotification[id] + " mins") : "";
		alert.Email ? SelectionsForPanelDescription.push("Email") : "";
		alert.TextMessage ? SelectionsForPanelDescription.push("Text") : "";

		if (alert.SendAllDays) {
			SelectionsForPanelDescription.push("All Days");
		} else {
			this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
				if (alert['Send' + dayOfWeek] == true) {
					DaysSelectionsForPanelDescription.push(dayOfWeek);
				}
			});

			if (DaysSelectionsForPanelDescription.length > 0) {
				SelectionsForPanelDescription.push("Days: (" + DaysSelectionsForPanelDescription.join(", ") + ")");
			}
		}

		if (alert.SendAllHours) {
			SelectionsForPanelDescription.push("All Hours");
		} else {

			//
			var UserTimeZoneOffset = this.utilityService.GetUserTimeZoneOffsetHours();

			this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((hourOfDay) => {


				var hourAsTwoDigitString = hourOfDay.toString().padStart(2, "0");

				if (alert["SendHour" + hourAsTwoDigitString] == true) {
					HoursSelectionsForPanelDescription.push(hourOfDay-UserTimeZoneOffset);
				}
			});

			if (HoursSelectionsForPanelDescription.length > 0) {
				SelectionsForPanelDescription.push("Hours: (" + HoursSelectionsForPanelDescription.join(", ") + ")");
			}
		}

		// if (this.buttonSelections[category].MinutesToEscalation[id] && this.widgetObject.reportsToPerson) {
		// 	SelectionsForPanelDescription.push("Escalate To " + this.widgetObject.reportsToPerson.GivenName + " " + this.widgetObject.reportsToPerson.FamilyName + " after " + this.buttonSelections[category].MinutesToEscalation[id] + " mins");
		// }


		// if(category == 'tag' || category == 'JbtStandardObservation' || category == 'CriticalGSEAssetType' || category == 'GSEAssetType' || category == 'CriticalGSEAssetModel' || category == 'GSEAssetModel') {
		// 	this.validation[category].tagDescription[id] = SelectionsForPanelDescription.join(", ");
		// }
		// else {
		// 	this.validation[category].description = SelectionsForPanelDescription.join(", ");
		// }

		return SelectionsForPanelDescription.join(", ");

	}


	// openAlertManagementPopupComponent(dataItem, itemObj) {
	// 	let widgetObject = this.configureWidgetObject(dataItem);

	// 	const dlg = this.dialog.open(AlertManagementPopupComponent, {
	// 		width: Global.isMobile ? "90%" : "40%",
	// 		height: Global.isMobile ? "80%" : "70%",
	// 		data: {
	// 			alertLevel: dataItem.category,
	// 			widget: widgetObject,
	// 			dataItem: dataItem,
	// 			item: itemObj,
	// 		},
	// 		maxWidth: "100vw",
	// 		maxHeight: "100vh"
	// 	});
	// 	let d = dlg.afterClosed().subscribe((result) => {
	// 		Global.User.DebugMode && console.log("AlertManagementPopupComponent after closed")
	// 	});
	// }


	// public onRightClickSelect({ dataItem, item }): void {

	// 	if (item === "Edit Alert Notification Settings") {
	// 		let itemObj;
	// 		if (dataItem.category == "asset") {
	// 			itemObj = this.dataService.cache.assets.find((a) => a.Id == dataItem.id);
	// 			this.openAlertManagementPopupComponent(dataItem, itemObj);

	// 		}
	// 		else if (dataItem.category == "tag" && dataItem.siteId == null && dataItem.fleetId == null) {
	// 			itemObj = this.dataService.cache.tagsObject[dataItem.id];
	// 			if(itemObj) {
	// 				this.openAlertManagementPopupComponent(dataItem, itemObj);
	// 			} else {
	// 				this.dataService.SQLActionAsPromise("API.GetTagsByListOfIds '" + dataItem.id + "'")
	// 					.then((data: any) => {
	// 						console.log('tag data:', data);
	// 						if(data.length > 0) {
	// 							itemObj = data[0];
	// 							itemObj.TagId = itemObj.Id;
	// 							itemObj.ShortTagName = itemObj.StandardName;
	// 							this.openAlertManagementPopupComponent(dataItem, itemObj);
	// 						}
	// 				});
	// 			}
	// 		}
	// 		else if (dataItem.category == "tag" && (dataItem.siteId != null || dataItem.fleetId != null) ) {
	// 			itemObj = this.dataService.cache.jbtStandardObservationsObject[dataItem.id];
	// 			itemObj.ShortTagName = itemObj.Name;
	// 			itemObj.JBTStandardObservationId = itemObj.Id;

	// 			this.openAlertManagementPopupComponent(dataItem, itemObj);
	// 		}
	// 		else {
	// 			this.openAlertManagementPopupComponent(dataItem, itemObj);
	// 		}


	// 	}
	// }

	configureWidgetObject(dataItem): any {
		var widgetObject;
		if(dataItem.category == 'site') {
			widgetObject = {
				WidgetSiteId: dataItem.id,
				VocationalSettingsJSON: null,
			};
		}
		else if(dataItem.category == 'tag') {
			const fleetConfig = {
				type: "Fleet",
				id: dataItem.fleetId,
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			widgetObject = {
				WidgetSiteId: dataItem.siteId,
				VocationalSettingsJSON: dataItem.fleetId ? stringFleetConfig : null,
			};
		}
		else if(dataItem.category == 'asset') {
			widgetObject = {
				WidgetAssetId: dataItem.id,
				VocationalSettingsJSON: null,
			};
		}
		else if(dataItem.category == 'fleet') {
			const fleetConfig = {
				type: "Fleet",
				id: dataItem.id,
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			widgetObject = {
				WidgetSiteId: null,
				VocationalSettingsJSON: stringFleetConfig,
			};
		}

		return widgetObject;
	}



	GetAssetTypesAndModels()
	{
		this.assetTypes = this.dataService.cache.assetTypes.filter((assetType) => {
			return assetType.AssetTypeGroupId > 1;
		}).sort((a, b) => {
			let fa = a.Name.toLowerCase(),
				fb = b.Name.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});

		let assetTypeIdList = this.assetTypes.map(assetType=>assetType.Id);

		this.assetModels = this.dataService.cache.assetModels.filter((assetModel) => {
			return assetTypeIdList.contains(assetModel.AssetTypeId);
		});


		this.assetTypes.forEach((assetType) => {

			var myModels = this.dataService.cache.assetModels.filter((assetModel) => {
				return assetModel.AssetTypeId == assetType.Id;
			}).sort((a, b) => {
				let fa = a.SelectorName.toLowerCase(),
					fb = b.SelectorName.toLowerCase();

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});

			assetType.models = myModels;

		});
	}

	onCancel() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}

		if (this.signalRAlerts$) {
			this.signalRAlerts$.unsubscribe();
		}

		if (this.signalRSubscription) {
			this.signalRSubscription.unsubscribe();
		}

	}


}
