<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Chart...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<sass-helper></sass-helper>
<div
	*ngIf="!isDataLoading && displayTimeScopeMessage"
	style="width: 100%; height: 100%"
>
	<lib-centered-message
		[centeredMessage]="timeScopeMessage"
	></lib-centered-message>
</div>

<mat-tab-group
	animationDuration="0ms"
	[selectedIndex]="materialTabSelected"
	(selectedTabChange)="tabHasChanged($event)"
>
	<mat-tab [label]="'Chart'">
		<div *ngIf="widgetObject !== undefined" style="height: 100%"
			 [ngClass]="{'conditionalScrollOverflow': overflowScroll, 'conditionalHiddenOverflow': !overflowScroll}">
			<div
				id="tagGraphWidget{{ widgetObject.WidgetId }}"
				class="lone-chart-template"
				style="width: 100%; height: 100%">
			</div>
		</div>
		<div *ngIf="tagModalInputObject !== undefined" style="height: 100%"
			 [ngClass]="{'conditionalScrollOverflow': overflowScroll, 'conditionalHiddenOverflow': !overflowScroll}">
			<div
				id="tagGraphSingleTag{{ tagModalInputObject.TagId }}"
				class="lone-chart-template"
				style="width: 100%; height: 100%">
			</div>
		</div>
	</mat-tab>
	<mat-tab *ngIf="rawGridData?.length > 0" [label]="'Table'">
		<h4 style="text-align: center;">Min: {{this.min}} | Max: {{this.max}} | Avg: {{this.average?.toFixed(2)}} | State Changes: {{this.stateChanges}} | Diff: {{this.diff?.toFixed(2)}}</h4>
		<lib-kendo-grid-parent
		[gridData]="rawGridData"
		[gridSettings]="gridSettings"
		[widgetObject]="widgetObject"
		[style.height.%]="88"
		filterable="menu"
		[sortable]="true"
		[reorderable]="true"
		[resizable]="true"
		#tagDataGrid
		*ngIf="rawGridData?.length > 0"
	>
	</lib-kendo-grid-parent>
	</mat-tab>

</mat-tab-group>


